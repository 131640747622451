import React, { useState, useCallback, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';

function ImageView({ image, onClose }) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const transformRef = useRef(null);

    const handleImageLoad = useCallback((e) => {
        setImageLoaded(true);
        console.log('Image loaded:', {
            naturalWidth: e.target.naturalWidth,
            naturalHeight: e.target.naturalHeight,
            clientWidth: e.target.clientWidth,
            clientHeight: e.target.clientHeight,
            offsetWidth: e.target.offsetWidth,
            offsetHeight: e.target.offsetHeight
        });
        
        setTimeout(() => {
            if (transformRef.current) {
                transformRef.current.resetTransform();
            }
        }, 50);
    }, []);

    console.log('Render - imageLoaded:', imageLoaded);

    return (
        <div className="image-view-container">
            <IconButton 
                className="close-button" 
                onClick={onClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            
            <TransformWrapper
                ref={transformRef}
                initialScale={1}
                minScale={1}
                maxScale={12}
                centerOnInit={false}
                wheel={{ 
                    step: 100,
                    smoothStep: 0.005,
                    wheelAnimationType: "linear"
                }}
                limitToBounds={false}
                centerZoomedOut={true}
                doubleClick={{ disabled: true }}
                defaultPositionX={0}
                defaultPositionY={0}
                alignmentAnimation={{
                    sizeX: 0,
                    sizeY: 0,
                    animationTime: 200,
                    animationType: "easeOut"
                }}
                wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {({ zoomIn, zoomOut, resetTransform }) => (
                    <>
                        <div className="tools">
                            <IconButton 
                                onClick={() => zoomIn()}
                                className="tool-button"
                                aria-label="zoom in"
                            >
                                <AddIcon />
                            </IconButton>
                            <IconButton 
                                onClick={() => zoomOut()}
                                className="tool-button"
                                aria-label="zoom out"
                            >
                                <RemoveIcon />
                            </IconButton>
                            <IconButton 
                                onClick={() => resetTransform()}
                                className="tool-button"
                                aria-label="reset zoom"
                            >
                                <RestartAltIcon />
                            </IconButton>
                        </div>
                        <TransformComponent
                            wrapperClass="transform-wrapper"
                            contentClass="transform-content"
                            wrapperStyle={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <img
                                src={`/api/serve-image/${image.filename}`}
                                alt={image.filename}
                                className="full-image"
                                onLoad={handleImageLoad}
                                style={{ 
                                    maxHeight: "90vh",
                                    width: "auto",
                                    objectFit: "contain"
                                }}
                            />
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
        </div>
    );
}

export default ImageView;
