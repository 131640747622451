import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageView from '../components/imageView';
import EmailPopup from '../components/emailPopup';
import { useNavigate } from 'react-router-dom';

function ImagePage() {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const { title } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await fetch(`/api/image/${title}`);
                if (!response.ok) {
                    throw new Error('Image not found');
                }
                const data = await response.json();
                setImage(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchImage();

        const hasClosedPopup = localStorage.getItem('popupClosed');
        console.log('Has closed popup:', hasClosedPopup);
        
        if (!hasClosedPopup) {
            console.log('Setting popup timer...');
            const popupTimer = setTimeout(() => {
                console.log('Timer finished, showing popup...');
                setShowPopup(true);
            }, 15000);
            
            return () => clearTimeout(popupTimer);
        }
    }, [title]);

    const handleClose = () => {
        navigate('/');
    };

    const handlePopupClose = (reason) => {
        setShowPopup(false);
        // Optionally handle different close reasons (subscribed, declined, already)
        localStorage.setItem('popupClosed', 'true');
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!image) return <div>Image not found</div>;

    return (
        <>
            <ImageView image={image} onClose={handleClose} />
            {showPopup && <EmailPopup onClose={handlePopupClose} />}
        </>
    );
}

export default ImagePage; 