import React, { useState } from 'react';
import axios from 'axios';
import '../styles/uploadForm.css';

function UploadForm() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setUploadStatus('');
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a file first');
      return;
    }

    if (!title.trim()) {
      setUploadStatus('Please enter a title');
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('title', title);

    const credentials = sessionStorage.getItem('credentials');

    try {
      await axios.post('/api/upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Basic ${credentials}`
        },
      });
      setUploadStatus('success');
      setSelectedFile(null);
      setTitle('');
      // Reset file input
      document.getElementById('fileInput').value = '';
    } catch (error) {
      setUploadStatus('error');
    } finally {
      setIsUploading(false);
    }
  };

  const getStatusMessage = () => {
    if (!uploadStatus) return null;
    
    if (uploadStatus === 'success') {
      return <div className="status-message success">Upload successful!</div>;
    }
    
    if (uploadStatus === 'error') {
      return <div className="status-message error">Upload failed. Please try again.</div>;
    }

    return <div className="status-message error">{uploadStatus}</div>;
  };

  return (
    <section className="upload-section">
      <h2>Upload Image</h2>
      <div className="upload-container">
        <div className="input-group">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter image title"
            className="title-input"
          />
        </div>
        
        <div className="file-input-container">
          <label className="file-input-label" htmlFor="fileInput">
            {selectedFile ? 'Change File' : 'Choose an image file'}
          </label>
          <input
            className="file-input"
            id="fileInput"
            type="file"
            onChange={handleFileSelect}
            accept="image/*"
          />
          {selectedFile && (
            <div className="selected-file-name">
              Selected: {selectedFile.name}
            </div>
          )}
        </div>
        
        <button 
          className="upload-button"
          onClick={handleUpload}
          disabled={!selectedFile || !title.trim() || isUploading}
        >
          {isUploading ? 'Uploading...' : 'Upload Image'}
        </button>
        
        {getStatusMessage()}
      </div>
    </section>
  );
}

export default UploadForm;