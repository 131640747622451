import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';
import MainGallery from './components/mainGallery';
import UploadForm from './pages/uploadForm';
import Login from './pages/Login';
import ImagePage from './pages/ImagePage';
import logo from './static/logo_bw.png';
import './styles/app.css';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import UploadIcon from '@mui/icons-material/Upload';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if credentials exist in sessionStorage
    const credentials = sessionStorage.getItem('credentials');
    setIsAuthenticated(!!credentials);
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('credentials');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div className="app-container">
        <header>
          <Link to="/" className="logo-container">
            <img src={logo} alt="Event Map Gallery" className="logo" />
          </Link>
          <div className="header-controls">
            {isAuthenticated ? (
              <>
                <Link to="/upload" className="header-button">
                  <IconButton color="inherit" aria-label="upload">
                    <UploadIcon />
                  </IconButton>
                </Link>
                <IconButton 
                  color="inherit" 
                  onClick={handleLogout} 
                  aria-label="logout"
                  className="header-button"
                >
                  <LogoutIcon />
                </IconButton>
              </>
            ) : null}
          </div>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<MainGallery />} />
            <Route path="/image/:title" element={<ImagePage />} />
            <Route 
              path="/upload" 
              element={isAuthenticated ? <UploadForm /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/login" 
              element={!isAuthenticated ? <Login onLogin={handleLogin} /> : <Navigate to="/upload" />} 
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;

