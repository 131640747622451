import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import axios from 'axios';

function ThumbnailView({ images, onImageSelect, onImageDelete }) {
    const handleDelete = async (event, imageId) => {
        event.stopPropagation(); // Prevent image selection when clicking delete
        
        if (!window.confirm('Are you sure you want to delete this image?')) {
            return;
        }

        const credentials = sessionStorage.getItem('credentials');
        if (!credentials) {
            return;
        }

        try {
            await axios.delete(`/api/images/${imageId}`, {
                headers: {
                    'Authorization': `Basic ${credentials}`
                }
            });
            if (onImageDelete) {
                onImageDelete(imageId);
            }
        } catch (error) {
            console.error('Error deleting image:', error);
            alert('Failed to delete image');
        }
    };

    const isAdmin = !!sessionStorage.getItem('credentials');

    return (
        <div className="thumbnail-grid">
            {images.map((image) => (
                <div 
                    key={image.id} 
                    className="thumbnail"
                    onClick={() => onImageSelect(image)}
                >
                    <img 
                        src={`/api/serve-thumbnail/${image.thumbnailFilename}`}
                        alt={image.title}
                        loading="lazy"
                    />
                    <div className="thumbnail-title">{image.title}</div>
                    {isAdmin && (
                        <IconButton
                            className="delete-button"
                            onClick={(e) => handleDelete(e, image.id)}
                            aria-label="delete"
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            ))}
        </div>
    );
}

export default ThumbnailView;