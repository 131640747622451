import React, { useState, useEffect } from 'react';
import '../styles/emailPopup.css';

function EmailPopup({ onClose }) {
    const [email, setEmail] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        // If success state is true, set a timer to close the popup
        if (isSuccess) {
            const timer = setTimeout(() => {
                onClose('subscribed');
            }, 1500); // 1.5s total (1s delay + 0.5s fade)
            return () => clearTimeout(timer);
        }
    }, [isSuccess, onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                setIsSuccess(true);
            }
        } catch (error) {
            console.error('Subscription error:', error);
        }
    };

    const handleResponse = async (responseType) => {
        try {
            const response = await fetch(`/api/popup-response/${responseType}`, {
                method: 'POST',
            });
            if (response.ok) {
                onClose(responseType);
            }
        } catch (error) {
            console.error('Response recording error:', error);
            onClose(responseType); // Still close the popup even if recording fails
        }
    };

    return (
        <div className="email-popup-overlay">
            <div className={`email-popup ${isSuccess ? 'success' : ''}`}>
                {isSuccess ? (
                    <h3 className="success-message">Success! ✨</h3>
                ) : (
                    <>
                        <h3>Subscribe to Boundless Discovery</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                            />
                            <button type="submit">Subscribe</button>
                        </form>
                        <div className="popup-buttons">
                            <button 
                                onClick={() => handleResponse('declined')}
                                className="secondary-button"
                            >
                                Thanks, but no thanks 🙏
                            </button>
                            <button 
                                onClick={() => handleResponse('already')}
                                className="secondary-button"
                            >
                                Already Subscribed 😎
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default EmailPopup;