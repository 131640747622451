import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ThumbnailView from './thumbnailView';
import { useNavigate } from 'react-router-dom';
import '../styles/mainGallery.css';

function MainGallery() {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const response = await axios.get('/api/images');
            setImages(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching images:', error);
            setLoading(false);
        }
    };

    const handleImageSelect = (image) => {
        const urlTitle = image.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/image/${urlTitle}`);
    };

    const handleImageDelete = (deletedImageId) => {
        setImages(images.filter(image => image.id !== deletedImageId));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="gallery-container">
            <ThumbnailView 
                images={images} 
                onImageSelect={handleImageSelect} 
                onImageDelete={handleImageDelete}
            />
        </div>
    );
}

export default MainGallery;